// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.track {
  display: none;
  min-height: 100px;
  background-color: rgba(58, 91, 204, 0.1490196078);
}
@media (max-width: 768px) {
  .track {
    min-height: 80px;
  }
}
@media (max-width: 480px) {
  .track {
    display: none;
  }
}
.track__content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.track__title {
  font-size: 22px;
  font-weight: 600;
  color: var(--e-global-color-primary);
}
@media (max-width: 768px) {
  .track__title {
    font-size: 18px;
  }
}

.swiper {
  margin-top: 30px !important;
}
@media (max-width: 768px) {
  .swiper {
    margin-top: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Track/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,iDAAA;AACJ;AAAI;EAJJ;IAKQ,gBAAA;EAGN;AACF;AAFI;EAPJ;IAQQ,aAAA;EAKN;AACF;AAFI;EACI,aAAA;EACA,6BAAA;EACA,mBAAA;AAIR;AADI;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;AAGR;AADQ;EALJ;IAMQ,eAAA;EAIV;AACF;;AACA;EACI,2BAAA;AAEJ;AADI;EAFJ;IAGQ,gBAAA;EAIN;AACF","sourcesContent":[".track {\n    display: none;\n    min-height: 100px;\n    background-color: #3A5BCC26;\n    @media(max-width: 768px) {\n        min-height: 80px;\n    }\n    @media(max-width: 480px) {\n        display: none;\n    }\n   \n\n    &__content {\n        display: flex;\n        justify-content: space-around;\n        align-items: center;\n    }\n\n    &__title {\n        font-size: 22px;\n        font-weight: 600;\n        color: var(--e-global-color-primary);\n\n        @media(max-width: 768px) {\n            font-size: 18px;\n        }\n        \n    }\n}\n\n.swiper {\n    margin-top: 30px !important;\n    @media(max-width: 768px) {\n        margin-top: 25px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

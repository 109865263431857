// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bagpicture__image {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 595px) {
  .bagpicture__image {
    justify-content: center;
  }
}
.bagpicture__image img {
  width: 135px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .bagpicture__image img {
    width: 100px;
  }
}
@media (max-width: 450px) {
  .bagpicture__image img {
    width: 75px;
  }
}

.overlay__modalpicture {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.modalpicture {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
}
.modalpicture__content {
  padding: 15px 25px;
  display: flex;
}
.modalpicture__close {
  display: flex;
  justify-content: right;
}
.modalpicture__image {
  width: 400px;
}
@media (max-width: 450px) {
  .modalpicture__image {
    width: 250px;
  }
}
.modalpicture__arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/BagConstructor/BagPicture/style.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,8BAAA;AAAR;AACQ;EAHJ;IAIQ,uBAAA;EAEV;AACF;AADQ;EACI,YAAA;EACA,eAAA;AAGZ;AAFY;EAHJ;IAIQ,YAAA;EAKd;AACF;AAJY;EANJ;IAOQ,WAAA;EAOd;AACF;;AADA;EACI,eAAA;EACF,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,oCAAA;EAEA,WAAA;AAGF;;AAGA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,mBAAA;AAAA;AAGA;EACI,kBAAA;EACA,aAAA;AADJ;AAKA;EACI,aAAA;EACA,sBAAA;AAHJ;AAOA;EAEI,YAAA;AANJ;AAOI;EAHJ;IAIQ,YAAA;EAJN;AACF;AAUA;EACI,eAAA;EACA,aAAA;EACA,mBAAA;AARJ","sourcesContent":[".bagpicture { \n    &__image{\n        display: flex;\n        justify-content: space-between;\n        @media(max-width: 595px) {\n            justify-content: center;\n        }\n        img {\n            width: 135px;\n            cursor: pointer;\n            @media(max-width: 768px) {\n                width: 100px;\n            }\n            @media(max-width: 450px) {\n                width: 75px;\n            }\n        }\n    }\n}\n\n\n.overlay__modalpicture {\n    position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, 0.8);\n  \n  z-index: 10;\n}\n\n\n\n//modalpicture\n.modalpicture {\nposition: absolute;\ntop: 50%;\nleft: 50%;\ntransform: translate(-50%, -50%);\nbackground: #ffffff;\n\n\n&__content {\n    padding: 15px 25px;\n    display: flex;\n}\n\n\n&__close {\n    display: flex;\n    justify-content: right;\n   \n}\n\n&__image {\n    \n    width: 400px;\n    @media(max-width: 450px) {\n        width: 250px;\n    }\n    \n\n   \n}\n\n&__arrow {\n    cursor: pointer;\n    display: flex; \n    align-items: center;\n  \n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
.notfound__title {
  font-size: 25px;
  font-weight: 500;
  color: var(--e-global-color-primary);
  margin-bottom: 15px;
}
.notfound__button {
  display: inline-block;
  margin: 0 auto;
  width: 220px;
  height: 50px;
  border-radius: 10px;
  background-color: #3A5BCC;
  border: 2px solid #3A5BCC;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  color: #ffffff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.notfound__button:hover {
  background-color: #ffffff;
  color: #4949cf;
}
@media (max-width: 1024px) {
  .notfound__button:hover {
    box-shadow: none;
    background-color: #3A5BCC;
    border: 2px solid #3A5BCC;
    color: #ffffff;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NotFound/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,mBAAA;AACR;AAGI;EACI,qBAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,2BAAA;AADR;AAEQ;EACI,yBAAA;EACA,cAAA;AAAZ;AACW;EAHH;IAII,gBAAA;IACA,yBAAA;IACA,yBAAA;IACA,cAAA;EAEV;AACF","sourcesContent":[".notfound {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 50vh;\n    \n    &__title {\n        font-size: 25px;\n        font-weight: 500;\n        color: var(--e-global-color-primary);\n        margin-bottom: 15px;\n       \n    }\n\n    &__button {\n        display: inline-block;\n        margin: 0 auto;\n        width: 220px;\n        height: 50px;\n        border-radius: 10px;\n        background-color: #3A5BCC;\n        border: 2px solid #3A5BCC;\n        color: #ffffff;\n        text-align: center;\n        font-size: 18px;\n        font-weight: 500;\n        white-space: nowrap;\n        color: #ffffff;\n        position: absolute;\n        left: 50%;\n        transform: translateX(-50%);\n        &:hover {\n            background-color: #ffffff;\n            color: #4949cf;\n           @media(max-width: 1024px) {\n            box-shadow: none;\n            background-color: #3A5BCC;\n            border: 2px solid #3A5BCC;\n            color: #ffffff;\n           }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

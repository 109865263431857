// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carmat__image {
  position: relative;
}
@media (max-width: 595px) {
  .carmat__image {
    margin-top: 15px;
  }
}
.carmat .image_one {
  position: relative;
  top: 0;
  left: 0;
  width: 95%;
}
@media (max-width: 595px) {
  .carmat .image_one {
    width: 40%;
  }
}
@media (max-width: 480px) {
  .carmat .image_one {
    width: 200px;
  }
}
.carmat .image_two {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
}
@media (max-width: 595px) {
  .carmat .image_two {
    width: 40%;
  }
}
@media (max-width: 480px) {
  .carmat .image_two {
    width: 200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Product/Carmat/style.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;AAAR;AACQ;EAFJ;IAGQ,gBAAA;EAEV;AACF;AACI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,UAAA;AACR;AAAQ;EALJ;IAMQ,UAAA;EAGV;AACF;AAFQ;EARJ;IASQ,YAAA;EAKV;AACF;AAFM;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,UAAA;AAIR;AAHQ;EALF;IAMM,UAAA;EAMV;AACF;AALQ;EARF;IASM,YAAA;EAQV;AACF","sourcesContent":[".carmat {\n    &__image {\n        position: relative;\n        @media(max-width: 595px) {\n            margin-top: 15px;\n        } \n    }\n\n    .image_one {\n        position: relative;\n        top: 0;\n        left:0;\n        width: 95%;\n        @media(max-width: 595px) {\n            width: 40%;\n        }\n        @media(max-width: 480px) {\n            width: 200px;\n        }\n      }\n\n      .image_two {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 95%;\n        @media(max-width: 595px) {\n            width: 40%;\n        }\n        @media(max-width: 480px) {\n            width: 200px;\n        }\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

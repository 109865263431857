// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomsale {
  margin-top: 45px;
}
@media (max-width: 460px) {
  .bottomsale {
    margin-top: 25px;
  }
}
.bottomsale__title {
  font-size: 24px;
  font-weight: bold;
  color: var(--e-global-color-primary);
}
@media (max-width: 460px) {
  .bottomsale__title {
    font-size: 20px;
  }
}
.bottomsale__content {
  margin-top: 25px;
  padding-bottom: 35px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 25px;
  justify-items: center;
  align-items: center;
}
@media (max-width: 1024px) {
  .bottomsale__content {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 991px) {
  .bottomsale__content {
    grid-column-gap: 10px;
  }
}
@media (max-width: 768px) {
  .bottomsale__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .bottomsale__content {
    grid-template-columns: repeat(2, 1fr);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Product/BottomSale/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EAFJ;IAGQ,gBAAA;EAGN;AACF;AAFI;EACI,eAAA;EACA,iBAAA;EACA,oCAAA;AAIR;AAHQ;EAJJ;IAKQ,eAAA;EAMV;AACF;AAHI;EACI,gBAAA;EACA,oBAAA;EACA,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,qBAAA;EACA,kBAAA;EACA,qBAAA;EACA,mBAAA;AAKR;AAJQ;EAVJ;IAWQ,qCAAA;EAOV;AACF;AANQ;EAbJ;IAcQ,qBAAA;EASV;AACF;AARQ;EAhBJ;IAiBQ,qCAAA;EAWV;AACF;AAVQ;EAnBJ;IAoBQ,qCAAA;EAaV;AACF","sourcesContent":[".bottomsale {\n    margin-top: 45px;\n    @media(max-width: 460px) {\n        margin-top: 25px;\n    }\n    &__title {\n        font-size: 24px;\n        font-weight: bold;\n        color: var(--e-global-color-primary);\n        @media(max-width: 460px) {\n            font-size: 20px;\n        }\n    }\n\n    &__content {\n        margin-top: 25px;\n        padding-bottom: 35px;\n        display: grid;\n        grid-template-columns: repeat(4, 1fr);\n        grid-template-rows: repeat(1, 1fr);\n        grid-column-gap: 20px;\n        grid-row-gap: 25px;\n        justify-items: center; \n        align-items: center;\n        @media(max-width: 1024px) {\n            grid-template-columns: repeat(3, 1fr);\n        }\n        @media(max-width: 991px) {\n            grid-column-gap: 10px;\n        }\n        @media(max-width: 768px) {\n            grid-template-columns: repeat(2, 1fr);\n        }\n        @media(max-width: 480px) {\n            grid-template-columns: repeat(2, 1fr);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brand {
  margin-top: 15px;
}
.brand__title {
  font-size: 18px;
  color: rgb(7, 7, 7);
  font-weight: 400;
  cursor: pointer;
}
.brand__title span {
  font-size: 19px;
  color: rgb(7, 7, 7);
  font-weight: 500;
}
.brand__modal {
  width: 70%;
  height: 100%;
  background-color: white;
  border: 2px solid grey;
  border-radius: 10px;
}
.brand__modal-content {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.brand__modal-item {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.brand__modal-item:hover {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/AdminProduct/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AAER;AADQ;EACI,eAAA;EACA,mBAAA;EACA,gBAAA;AAGZ;AAAI;EACI,UAAA;EACA,YAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;AAER;AAAQ;EACI,aAAA;EACA,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,qBAAA;EACA,kBAAA;AAEZ;AACQ;EACI,eAAA;EACA,gBAAA;EACA,eAAA;AACZ;AAAY;EACI,UAAA;AAEhB","sourcesContent":[".brand {\n    margin-top: 15px;\n    &__title {\n        font-size: 18px;\n        color: rgb(7,7,7);\n        font-weight: 400; \n        cursor: pointer;\n        span {\n            font-size: 19px;\n            color: rgb(7,7,7);\n            font-weight: 500;\n        }\n    }\n    &__modal {\n        width: 70%;\n        height: 100%;\n        background-color: #ffff;\n        border: 2px solid grey;\n        border-radius: 10px;\n\n        &-content {\n            padding: 10px;\n            display: grid;\n            grid-template-columns: repeat(8, 1fr);\n            grid-template-rows: repeat(7, 1fr);\n            grid-column-gap: 10px;\n            grid-row-gap: 10px;\n        }\n\n        &-item {\n            font-size: 16px;\n            font-weight: 500;\n            cursor: pointer;\n            &:hover {\n                color: red;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

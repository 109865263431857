// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bagtext {
  margin-top: 35px;
}
@media (max-width: 595px) {
  .bagtext {
    margin-left: 25px;
    margin-top: 25px;
  }
}
.bagtext__item {
  font-size: 18px;
  font-weight: 400;
  color: var(--e-global-color-primary);
  line-height: 1.2;
}
@media (max-width: 460px) {
  .bagtext__item {
    font-size: 16px;
  }
}
.bagtext__item span {
  font-weight: 600;
  font-size: 19px;
  color: var(--e-global-color-primary);
}
@media (max-width: 460px) {
  .bagtext__item span {
    font-size: 17px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/BagConstructor/BagText/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EAFJ;IAGQ,iBAAA;IACA,gBAAA;EAGN;AACF;AACI;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,gBAAA;AACR;AAAQ;EALJ;IAMQ,eAAA;EAGV;AACF;AAFQ;EACI,gBAAA;EACA,eAAA;EACA,oCAAA;AAIZ;AAHY;EAJJ;IAKQ,eAAA;EAMd;AACF","sourcesContent":[".bagtext {\n    margin-top: 35px;\n    @media(max-width: 595px) {\n        margin-left: 25px;\n        margin-top: 25px;\n    }\n\n   \n\n    &__item {\n        font-size: 18px;\n        font-weight: 400;\n        color: var(--e-global-color-primary); \n        line-height: 1.2;\n        @media(max-width: 460px) {\n            font-size: 16px;\n        }\n        span {\n            font-weight: 600;\n            font-size: 19px;\n            color: var(--e-global-color-primary); \n            @media(max-width: 460px) {\n                font-size: 17px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

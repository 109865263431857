// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.burger {
  display: none;
}
@media (max-width: 595px) {
  .burger {
    display: block;
    margin-top: 12px;
  }
}
@media (max-width: 480px) {
  .burger {
    margin-top: 12px;
  }
}
@media (max-width: 430px) {
  .burger {
    margin-top: 9px;
  }
}
.burger__border {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
}
@media (max-width: 480px) {
  .burger__border {
    width: 20px;
    height: 2px;
    background-color: black;
    margin: 4px 0;
  }
}
@media (max-width: 430px) {
  .burger__border {
    height: 3px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Burger/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EAFJ;IAGQ,cAAA;IACA,gBAAA;EAGN;AACF;AAFI;EANJ;IAOQ,gBAAA;EAKN;AACF;AAJI;EATJ;IAUQ,eAAA;EAON;AACF;AALI;EACI,WAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;AAOR;AANQ;EALJ;IAMQ,WAAA;IACA,WAAA;IACA,uBAAA;IACA,aAAA;EASV;AACF;AARQ;EAXJ;IAYQ,WAAA;EAWV;AACF","sourcesContent":[".burger {\n    display: none;\n    @media(max-width: 595px) {\n        display: block;\n        margin-top: 12px;\n    }\n    @media(max-width: 480px) {\n        margin-top: 12px;\n    }\n    @media(max-width: 430px) {\n        margin-top: 9px;\n    }\n\n    &__border {\n        width: 25px;\n        height: 3px;\n        background-color: black;\n        margin: 4px 0;\n        @media(max-width: 480px) {\n            width: 20px;\n            height: 2px;\n            background-color: black;\n            margin: 4px 0;\n        }\n        @media(max-width: 430px) {\n            height: 3px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

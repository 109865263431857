// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car__body {
  animation: shake 0.2s ease-in-out infinite alternate;
}
.car__line {
  transform-origin: center right;
  stroke-dasharray: 22;
  animation: line 0.8s ease-in-out infinite;
  animation-fill-mode: both;
}
.car__line--top {
  animation-delay: 0s;
}
.car__line--middle {
  animation-delay: 0.2s;
}
.car__line--bottom {
  animation-delay: 0.4s;
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}
@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }
  25% {
    stroke-dashoffset: 22;
  }
  50% {
    stroke-dashoffset: 0;
  }
  51% {
    stroke-dashoffset: 0;
  }
  80% {
    stroke-dashoffset: -22;
  }
  100% {
    stroke-dashoffset: -22;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,aAAA;EACG,mBAAA;EACH,uBAAA;AACD;;AAGC;EACC,oDAAA;AAAF;AAGC;EACC,8BAAA;EACA,oBAAA;EACA,yCAAA;EACA,yBAAA;AADF;AAGE;EACC,mBAAA;AADH;AAIE;EACC,qBAAA;AAFH;AAKE;EACC,qBAAA;AAHH;;AAQA;EACC;IACC,0BAAA;EALA;EAOD;IACC,yBAAA;EALA;AACF;AAQA;EACC;IACC,qBAAA;EANA;EASD;IACC,qBAAA;EAPA;EAUD;IACC,oBAAA;EARA;EAWD;IACC,oBAAA;EATA;EAYD;IACC,sBAAA;EAVA;EAaD;IACC,sBAAA;EAXA;AACF","sourcesContent":[".loader {\n\theight: 100vh;\n\tdisplay: flex;\n    align-items: center;\n\tjustify-content: center;\n}\n\n.car {\n\t&__body {\n\t\tanimation: shake 0.2s ease-in-out infinite alternate;\n\t}\n\t\n\t&__line {\n\t\ttransform-origin: center right;\n\t\tstroke-dasharray: 22;\n\t\tanimation: line 0.8s ease-in-out infinite;\n\t\tanimation-fill-mode: both;\n\t\t\n\t\t&--top {\n\t\t\tanimation-delay: 0s;\n\t\t}\n\t\t\n\t\t&--middle {\n\t\t\tanimation-delay: 0.2s;\n\t\t}\n\t\t\n\t\t&--bottom {\n\t\t\tanimation-delay: 0.4s;\n\t\t}\n\t}\n}\n\n@keyframes shake {\n\t0% {\n\t\ttransform: translateY(-1%);\n\t}\n\t100% {\n\t\ttransform: translateY(3%);\n\t}\n}\n\n@keyframes line {\n\t0% {\n\t\tstroke-dashoffset: 22;\n\t}\n\t\n\t25% {\n\t\tstroke-dashoffset: 22;\n\t}\n\t\n\t50% {\n\t\tstroke-dashoffset: 0;\n\t}\n\t\n\t51% {\n\t\tstroke-dashoffset: 0;\n\t}\n\t\n\t80% {\n\t\tstroke-dashoffset: -22;\n\t}\n\t\n\t100% {\n\t\tstroke-dashoffset: -22;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

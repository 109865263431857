// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 595px) {
  .bagimage {
    display: flex;
    justify-content: center;
  }
}
.bagimage__image img {
  width: 470px;
}
@media (max-width: 1024px) {
  .bagimage__image img {
    width: 350px;
  }
}
@media (max-width: 595px) {
  .bagimage__image img {
    width: 300px;
  }
}
@media (max-width: 460px) {
  .bagimage__image img {
    width: 250px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/BagConstructor/BagImage/style.scss"],"names":[],"mappings":"AACI;EADJ;IAEQ,aAAA;IACA,uBAAA;EACN;AACF;AACQ;EACI,YAAA;AACZ;AAAY;EAFJ;IAGQ,YAAA;EAGd;AACF;AAFY;EALJ;IAMQ,YAAA;EAKd;AACF;AAJY;EARJ;IASQ,YAAA;EAOd;AACF","sourcesContent":[".bagimage {\n    @media(max-width: 595px) {\n        display: flex;\n        justify-content: center;\n    }\n    &__image{\n        img {\n            width: 470px;\n            @media(max-width: 1024px) {\n                width: 350px;\n            }\n            @media(max-width: 595px) {\n                width: 300px;\n            }\n            @media(max-width: 460px) {\n                width: 250px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pattern__car {
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  .pattern__car-image {
    display: none;
  }
}
.pattern__car-image img {
  width: 246px;
}
.pattern__schema, .pattern__trunk {
  margin-top: 25px;
}
.pattern__schema-image, .pattern__trunk-image {
  display: flex;
  justify-content: center;
}
.pattern__schema-image img, .pattern__trunk-image img {
  width: 300px;
}
.pattern__schema-text, .pattern__trunk-text {
  margin-top: 7px;
  font-size: 16px;
  text-align: center;
  color: #9b9999;
}
.pattern__schema-text__bottom, .pattern__trunk-text__bottom {
  font-size: 16px;
  text-align: center;
  color: #9b9999;
}`, "",{"version":3,"sources":["webpack://./src/components/Product/Pattern/style.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,uBAAA;AAAR;AAEY;EADJ;IAEQ,aAAA;EACd;AACF;AAAY;EACI,YAAA;AAEhB;AAII;EACI,gBAAA;AAFR;AAGQ;EACI,aAAA;EACA,uBAAA;AADZ;AAEY;EACI,YAAA;AAAhB;AAGQ;EACI,eAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;AADZ;AAEY;EACI,eAAA;EACA,kBAAA;EACA,cAAA;AAAhB","sourcesContent":[".pattern {\n    &__car {\n        display: flex;\n        justify-content: center;\n        &-image {\n            @media(max-width: 991px) {\n                display: none;\n            }\n            img {\n                width: 246px;\n            }\n            \n        }\n    }\n\n    &__schema, &__trunk {\n        margin-top: 25px;\n        &-image {\n            display: flex;\n            justify-content: center;\n            img {\n                width: 300px;\n            }\n        }\n        &-text {\n            margin-top: 7px;\n            font-size: 16px;\n            text-align: center;\n            color: #9b9999;\n            &__bottom {\n                font-size: 16px;\n                text-align: center;\n                color: #9b9999; \n            }\n            \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalrug {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 350px;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 25px 25px;
}
.modalrug__text {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--e-global-color-primary);
  margin-bottom: 15px;
}
.modalrug__button {
  display: block;
  margin: 0 auto;
  background-color: #3A5BCC;
  border: 2px solid #3A5BCC;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  width: auto;
  height: auto;
  cursor: pointer;
  text-align: center;
  padding: 5px 10px;
}

.overlay__modalrug {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/components/Product/modal/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAGE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,mBAAA;AADJ;AAIE;EACE,cAAA;EACA,cAAA;EACA,yBAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AAFJ;;AAOA;EACI,eAAA;EACF,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,oCAAA;EAEA,WAAA;AALF","sourcesContent":[".modalrug {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    min-width: 350px;\n    height: auto;\n    background: #ffffff;\n    border-radius: 10px;\n    padding: 25px 25px;\n    \n  \n  \n  &__text {\n    text-align: center;\n    font-size: 18px;\n    font-weight: 500;\n    color: var(--e-global-color-primary);\n    margin-bottom: 15px;\n    \n  }\n  &__button {\n    display: block;\n    margin: 0 auto;\n    background-color: #3A5BCC;\n    border: 2px solid #3A5BCC;\n    color: #ffffff;\n    font-size: 18px;\n    font-weight: 500;\n    width: auto;\n    height: auto;\n    cursor: pointer;\n    text-align: center;\n    padding: 5px 10px;\n\n  }\n}\n\n.overlay__modalrug {\n    position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, 0.8);\n  \n  z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

.wrapper {
  min-height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

img, svg {
  vertical-align: middle;
}

.carousel {
  margin-bottom: 45px;
}

.carousel-image {
  position: relative;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,sBAAA;AACJ;;AAIA;EACI,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;AADJ;;AAMA;EACI,iBAAA;EACA,cAAA;AAHJ;;AAMA;EACI,qBAAA;AAHJ;;AAOA;EACI,sBAAA;AAJJ;;AAOA;EACI,mBAAA;AAJJ;;AAOA;EACI,kBAAA;EAEA,WAAA;AALJ","sourcesContent":["* {\n    font-family: \"Montserrat\", sans-serif;\n    box-sizing: border-box;\n    \n}\n\n\n.wrapper {\n    min-height: 100%;\n    position: relative;\n    margin: 0;\n    padding: 0;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n\n\n\n.container {\n    max-width: 1300px;\n    margin: 0 auto;\n}\n\na {\n    text-decoration: none;\n    \n}\n\nimg, svg {\n    vertical-align: middle;\n}\n\n.carousel {\n    margin-bottom: 45px;\n}\n\n.carousel-image {\n    position: relative;\n\n    width: 100%;   \n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cdek__content {
  margin-top: 15px;
}
.cdek__item {
  margin-top: 25px;
}
.cdek__item-title {
  font-size: 17px;
  font-weight: 400;
  color: var(--e-global-color-primary);
}
.cdek__item-form {
  padding: 5px;
  border: 1px solid #b5b8b8;
  border-radius: 10px;
  width: 50%;
}
.cdek__item-form:focus {
  border-color: #3A5BCC;
  outline: none;
}
@media (max-width: 460px) {
  .cdek__item-form {
    width: 100%;
  }
}
.cdek__input {
  width: 300px;
  height: 35px;
  border: 1px solid #b5b8b8;
  border-radius: 5px;
  margin-right: 15px;
}
.cdek__input:focus {
  border-color: #3A5BCC;
  outline: none;
}
@media (max-width: 460px) {
  .cdek__input {
    width: 400px;
  }
}
@media (max-width: 400px) {
  .cdek__input {
    width: 350px;
    margin-bottom: 10px;
  }
}
@media (max-width: 360px) {
  .cdek__input {
    width: 320px;
  }
}
.cdek__bottom {
  margin-top: 15px;
}
.cdek__bottom-days, .cdek__bottom-price {
  font-size: 17px;
  font-weight: 500;
  color: var(--e-global-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/Cdek/style.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAII;EACI,gBAAA;AAFR;AAGQ;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;AADZ;AAIQ;EACI,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,UAAA;AAFZ;AAGY;EACI,qBAAA;EACA,aAAA;AADhB;AAGY;EATJ;IAUQ,WAAA;EAAd;AACF;AAII;EACI,YAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AAFR;AAGQ;EACI,qBAAA;EACA,aAAA;AADZ;AAGQ;EAVJ;IAWQ,YAAA;EAAV;AACF;AACQ;EAbJ;IAcQ,YAAA;IACA,mBAAA;EAEV;AACF;AADQ;EAjBJ;IAkBQ,YAAA;EAIV;AACF;AAAI;EACI,gBAAA;AAER;AAAQ;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;AAEZ","sourcesContent":[".cdek {\n    &__content {\n        margin-top: 15px;\n     \n    }\n\n    &__item {\n        margin-top: 25px;\n        &-title {\n            font-size: 17px;\n            font-weight: 400;\n            color: var(--e-global-color-primary);\n        }\n\n        &-form {\n            padding: 5px;\n            border: 1px solid #b5b8b8;\n            border-radius: 10px;\n            width: 50%;\n            &:focus {\n                border-color:  #3A5BCC; \n                outline: none;\n            }\n            @media(max-width: 460px) {\n                width: 100%;\n            }\n        }\n    }\n\n    &__input {\n        width: 300px;\n        height: 35px;\n        border: 1px solid #b5b8b8;\n        border-radius: 5px;\n        margin-right: 15px;\n        &:focus {\n            border-color:  #3A5BCC; \n            outline: none;\n        }\n        @media(max-width: 460px) {\n            width: 400px;\n        }\n        @media(max-width: 400px) {\n            width: 350px;\n            margin-bottom: 10px;\n        }\n        @media(max-width: 360px) {\n            width: 320px;\n           \n        }\n    }\n\n    &__bottom {\n        margin-top: 15px;\n\n        &-days, &-price {\n            font-size: 17px;\n            font-weight: 500;\n            color: var(--e-global-color-primary);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-control-prev, .carousel-control-next {
  opacity: 1 !important;
  transition: none !important;
  width: 10% !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 50px !important;
  height: 50px !important;
  background-size: 25px 25px !important;
  border-radius: 100% !important;
  background-color: #2e528f !important;
}
@media (max-width: 600px) {
  .carousel-control-next-icon, .carousel-control-prev-icon {
    width: 30px !important;
    height: 30px !important;
    background-size: 15px 15px !important;
  }
}

.carousel-indicators {
  top: 102% !important;
}
@media (max-width: 600px) {
  .carousel-indicators {
    top: 100% !important;
  }
}

.carousel-indicators [data-bs-target] {
  width: 15px !important;
  height: 15px !important;
  border-radius: 100% !important;
  background-color: #2e528f !important;
}

.banner__image {
  border-radius: 10px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/CarouselBanner/style.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,2BAAA;EACA,qBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,uBAAA;EACA,qCAAA;EACA,8BAAA;EACA,oCAAA;AACJ;AAAI;EANJ;IAOQ,sBAAA;IACA,uBAAA;IACA,qCAAA;EAGN;AACF;;AACA;EACI,oBAAA;AAEJ;AADI;EAFJ;IAGQ,oBAAA;EAIN;AACF;;AADA;EACI,sBAAA;EACA,uBAAA;EACA,8BAAA;EACA,oCAAA;AAIJ;;AACI;EACI,mBAAA;EACA,WAAA;AAER","sourcesContent":[".carousel-control-prev, .carousel-control-next {\n    opacity: 1 !important;\n    transition: none !important;\n    width: 10% !important;\n}\n\n.carousel-control-next-icon, .carousel-control-prev-icon {\n    width: 50px !important;\n    height: 50px !important;\n    background-size: 25px 25px !important;\n    border-radius: 100% !important;\n    background-color: #2e528f !important;\n    @media(max-width: 600px) {\n        width: 30px !important;\n        height: 30px !important;\n        background-size: 15px 15px !important;\n    }\n\n}\n\n.carousel-indicators {\n    top: 102% !important;\n    @media(max-width: 600px) {\n        top: 100% !important;\n    }\n}\n\n.carousel-indicators [data-bs-target] {\n    width: 15px !important;\n    height: 15px !important;\n    border-radius: 100% !important;\n    background-color: #2e528f !important;\n   \n}\n\n.banner {\n    &__image {\n        border-radius: 10px;\n        width: 100%;\n    \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
